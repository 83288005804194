<template>
  <!-- 产品-小象脑力 -->
  <div class="website-css Product-WLZG">
    <MenuHead @func="getMsgFormSon"></MenuHead>
    <!-- banner beg -->
    <div class="Solution-banner AboutUs-banner">
      <div class="w1200">
        <img
          src="https://eletell.oss-cn-beijing.aliyuncs.com/H5_Activity/bsyd-logo.png"
          class="wlzg-banner-icon wlzg-banner-logo"
        />

        <!-- <img
          src="../assets/image/Website-img/xxnl-banner1.png"
          class="wlzg-banner-icon wlzg-banner-icon3 opacity1"
        /> -->
        <img
          src="../assets/image/Website-img/bsyd-banner1.png"
          class="wlzg-banner-icon bsyd-banner-icon1 animated fadeIn"
        />

        <div class="banner-title">
          <p class="animated fadeInRight">
            {{ this.languageList.bannertitle }}
          </p>
          <p>{{ this.languageList.bannerContent }}</p>
          <img
            class="wlzg-banner-ewm animated flipInX"
            src="../assets/image/Website-img/bsyd-ewm.png"
          />
          <p class="ewm-text">{{ this.languageList.bannerContent1 }}</p>
        </div>
        <!-- <img src="../assets/image/Website-img/bannericon1.png" class="banner-icon3" /> -->
      </div>
    </div>
    <!-- banner end -->

    <!--模块一 系统化提升 beg-->
    <div class="w1200">
      <div class="wlzg-box">
        <div class="wlzg-box-text">
          <p>
            <span class="bsyd-span"></span>{{ this.languageList.list[0].title }}
          </p>
          <p>
            {{ this.languageList.list[0].content }}
          </p>
        </div>
        <div class="wlzg-box-img">
          <img :src="this.languageList.list[0].img" class="product1" />
        </div>
      </div>
    </div>
    <!--模块一 系统化提升 end-->

    <!--模块二 趣味式练习 beg-->
    <div class="Enterprise-bg-f8">
      <div class="w1200">
        <div class="wlzg-box">
          <div class="wlzg-box-img">
            <img :src="this.languageList.list[1].img" class="product2" />
          </div>
          <div class="wlzg-box-text text-right">
            <p>
              <span class="bsyd-span"></span
              >{{ this.languageList.list[1].title }}
            </p>
            <p>
              {{ this.languageList.list[1].content }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--模块二 趣味式练习 beg-->

    <!--模块三 个性化推荐 beg-->
    <div class="w1200">
      <div class="wlzg-box">
        <div class="wlzg-box-text">
          <p>
            <span class="bsyd-span"></span>{{ this.languageList.list[2].title }}
          </p>
          <p>
            {{ this.languageList.list[2].content }}
          </p>
        </div>
        <div class="wlzg-box-img">
          <img :src="this.languageList.list[2].img" class="product1" />
        </div>
      </div>
    </div>
    <!--模块三 个性化推荐 end-->

    <!--模块四 游戏化机制 beg-->
    <div class="Enterprise-bg-f8">
      <div class="w1200">
        <div class="wlzg-box">
          <div class="wlzg-box-img">
            <img :src="this.languageList.list[3].img" class="product2" />
          </div>
          <div class="wlzg-box-text text-right">
            <p>
              <span class="bsyd-span"></span
              >{{ this.languageList.list[3].title }}
            </p>
            <p>
              {{ this.languageList.list[3].content }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--模块四 游戏化机制 beg-->

    <web-bottom ref="child"></web-bottom>
  </div>
</template>

<script>
import WebBottom from '../components/WebBottom'
import ChineseJson from '../assets/json/Chinese-Json.json'
import EnglishJson from '../assets/json/English-Json.json'

export default {
  name: 'AboutUs',
  components: {
    WebBottom,
  },
  metaInfo: {
    title: '倍速阅读-北京大象智酷科技有限公司', // set a title
    meta: [
      // set meta
      {
        name: 'keywords',
        content:
          '阅读,提升阅读能力,提高阅读效率,阅读速度,阅读记忆,阅读理解,阅读专注',
      },
      {
        name: 'description',
        content:
          '系统化提升,趣味式练习,场景化阅读,实时性追踪,专家团队根据阅读过程中的大脑信息加工过程,定制专属的阅读能力提升方案,促进阅读速度、阅读记忆、阅读理解和阅读专注等阅读所需的认知能力综合提升与改善.',
      },
    ],
    // link: [{                 // set link
    //  		rel: 'asstes',
    //   	href: 'https://assets-cdn.github.com/'
    // }]
  },
  data() {
    return {
      ChineseJson: JSON.parse(JSON.stringify(ChineseJson)), //中文配表
      EnglishJson: JSON.parse(JSON.stringify(EnglishJson)), //英文配表
      languageJson: [],
      languageList: [],
    }
  },
  props: {
    msg: String,
  },
  created() {
    // this.langtype = localStorage.getItem("LanguageTypes");
    // console.log("语言类型", this.langtype);
    // this.IFType();
    if (localStorage.getItem('LanguageTypes') == undefined) {
      this.langtype = 1
      this.IFType()
    } else {
      this.langtype = localStorage.getItem('LanguageTypes')
      console.log('语言类型', this.langtype)
      this.IFType()
    }
  },
  mounted() {},
  methods: {
    IFType() {
      if (this.langtype == 1) {
        this.languageJson = this.ChineseJson
        console.log('中文配表', this.languageJson)
        this.getList()
      } else {
        this.languageJson = this.EnglishJson
        this.getList()
      }
    },
    getMsgFormSon(data) {
      // this.msgFormSon = data;
      console.log('home', data)
      this.langtype = data
      this.IFType()
      this.$refs.child.sing(this.langtype)
    },
    getList() {
      console.log('中文配表', this.languageJson)
      // this.ChineseJson.fo
      this.languageJson.forEach((item) => {
        if (item.title == 'Product-BSYD') {
          this.languageList = item.List[0]
        }
      })
      console.log(this.languageList)
    },
  },
}
</script>
<style scoped>
.AboutUs-banner {
  width: 100%;
  height: 715px;
  position: relative;
  background: #3cb560;
}
.Solution-banner {
}
.brain-ul li {
  float: left;
  width: 400px;
  height: 300px;
}
</style>